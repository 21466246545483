import React, { useState, useContext } from 'react';
import { arrayOf, string, shape } from 'prop-types';

import cx from 'classnames';
import { Switch } from '@andes/switch';

import useFilterSearch from '../../../../hooks/use-filter-search';
import HighlightedFilterVis from '../highlighted-filter-vis/highlighted-filter-vis';
import { FilterTooltip } from '../filters-tooltip/filters-tooltip.mobile';
import IconQuestion from '../../../icons/question';
import HighlightedFilterContent from './highlighted-filter-content';
import { FILTER_EXTENDED_PROPS } from './constants';
import { StaticPropsContext } from '../../../context/static-props';
import { linkBuildNewHref } from '../../../../components-v2/link';
import useMapConfig from '../../../map/hooks/use-map-config';
import { LAYOUTS } from '../../../../constants';
import { trackClickEvent } from '../../../map/track';
import { useSearch } from '../../../../hooks/context';
import { LayoutContext } from '../../../context/layout';

const namespace = 'ui-search-filter-highlighted';

const HighlightedFilter = ({
  filterId,
  highlight = null,
  label = null,
  subtitle = null,
  switch: { is_on: isOn } = {},
  title = null,
  tooltip = null,
  url = '',
}) => {
  const { queryParams } = useContext(StaticPropsContext);
  const { currentLayout } = React.useContext(LayoutContext);
  const filterSearch = useFilterSearch(url, filterId);
  const isClientSideFilter = !!filterSearch;
  const [isChecked, setChecked] = useState(isOn);
  const { layout_options, shops } = useSearch();
  const isShops = Boolean(shops);
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const handleClick = (e) => {
    if (e.type !== 'change') {
      e.preventDefault();
      setChecked(!isOn);
    }

    if (isClientSideFilter) {
      filterSearch();

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      return;
    }

    window.location.href = linkBuildNewHref({ href: url, queryParams, isInternal: true, currentLayout });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      action={url}
      onSubmit={handleClick}
      className={cx(`${namespace}__container`, { 'shops__filter-highlighted': isShops })}
      onKeyPress={handleClick}
    >
      <button
        name="button"
        type="submit"
        className={cx(`${namespace}__content ${namespace}__content-button`, {
          'shops__highlighted-button-tertiary': isShops,
        })}
      >
        <HighlightedFilterContent
          namespace={namespace}
          highlight={highlight}
          label={label}
          subtitle={subtitle}
          title={title}
          htmlForIDValue={filterId}
        />
      </button>

      {tooltip && (
        <div className={`${namespace}__tooltip`}>
          <FilterTooltip tooltip={tooltip} side="right">
            <div className={`${namespace}__tooltip-trigger`} />
            <IconQuestion />
          </FilterTooltip>
        </div>
      )}

      <div className={cx(`${namespace}__switch-container`, { 'shops__highlighted-switch': isShops })}>
        <Switch
          id={filterId}
          className={`${namespace}__switch-icon`}
          label=""
          checked={isChecked}
          autoComplete="off"
          onChange={handleClick}
        />
      </div>
    </form>
  );
};

const HighlightedFilterContainer = ({ className = '', expanded_values = [], id = '' }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <li className={cx(className, namespace, `${namespace}-${id}`, { 'shops__highlighted-lists': isShops })}>
      {expanded_values.map((value) => {
        if (id === 'approved_loan_highlighted') {
          return <HighlightedFilterVis {...value} filterId={id} key={value.id} />;
        }

        return <HighlightedFilter {...value} filterId={id} key={value.id} />;
      })}
    </li>
  );
};

HighlightedFilter.propTypes = {
  ...FILTER_EXTENDED_PROPS,
  filterId: string.isRequired,
};

HighlightedFilterContainer.propTypes = {
  className: string,
  expanded_values: arrayOf(shape(FILTER_EXTENDED_PROPS)),
  id: string,
  name: string,
};

HighlightedFilter.propTypes = {
  highlight: shape({}),
  label: string,
  subtitle: shape({}),
  switch: shape({}),
  title: shape({}),
  url: string,
};

export default HighlightedFilterContainer;
