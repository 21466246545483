import React from 'react';
import { arrayOf, func, element, oneOfType, string, shape, number } from 'prop-types';

import { LazyHydrate as Hydrator } from 'nordic/lazy';

import DesktopGridRow from './row.desktop';
import MobileGridRow from './row.mobile';
import { DEVICE_TYPES } from '../../../constants';
import { isIntervention, isItemIntervention } from '../renderer/utils';
import { renderInterventionsDesktop } from '../../intervention/utils/render-interventions/interventions.desktop';
import { renderInterventionsMobile } from '../../intervention/utils/render-interventions/interventions.mobile';

const namespace = 'ui-search-layout';

const renderMobileComponents = (currentLayoutItem, item, currentLayout, index) => {
  if (isIntervention(item)) {
    return (
      <li className={`${namespace}__item--intervention`}>
        <Hydrator whenVisible>{renderInterventionsMobile[item.id]({ item, position: index })}</Hydrator>
      </li>
    );
  }

  return item && <MobileGridRow itemComponent={currentLayoutItem} item={item} layout={currentLayout} index={index} />;
};

const renderDesktopComponents = (currentLayoutItem, item, currentLayout, deviceType, idx, cols) => {
  if (isIntervention(item) && !isItemIntervention(item)) {
    if (item.id === 'CART_INTERVENTION') {
      item.classname = 'grid__intervention';
    }

    if (item.id === 'CAROUSEL_INTERVENTION' && idx !== 0) {
      item.content.classname = 'mt-16';
    }

    return (
      <li className={`${namespace}__item--intervention`}>
        <Hydrator whenVisible>{renderInterventionsDesktop[item.id]({ item, position: idx, deviceType })}</Hydrator>
      </li>
    );
  }

  return (
    <DesktopGridRow itemComponent={currentLayoutItem} itemGroup={item} layout={currentLayout} cols={cols} idx={idx} />
  );
};

const chunkResults = (results, cols, LayoutItem, layout, device) => {
  let customIndex = 1;

  if (!device || device !== DEVICE_TYPES.MOBILE) {
    return results.map((itemGroup) => {
      const elementDesktop = renderDesktopComponents(LayoutItem, itemGroup, layout, device, customIndex, cols);
      if (!itemGroup.id.includes('INTERVENTION')) {
        customIndex += 1;
      }
      return elementDesktop;
    });
  }

  return results.map((item) => {
    const elementMobile = renderMobileComponents(LayoutItem, item, layout, customIndex);
    if (!item.id.includes('INTERVENTION')) {
      customIndex += 1;
    }
    return elementMobile;
  });
};

const GridLayout = ({ itemComponent: LayoutItem, results = [], layout, cols, deviceType = null }) =>
  chunkResults(results, cols, LayoutItem, layout, deviceType);

GridLayout.propTypes = {
  cols: number.isRequired,
  deviceType: string,
  itemComponent: oneOfType([element, func]).isRequired,
  layout: string.isRequired,
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

export default GridLayout;
