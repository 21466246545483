/* eslint-disable react/prop-types */

import React, { useState, useRef, useContext } from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Card } from '@andes/card';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';

import Wrapper from '../cpg/wrapper';
import ItemBookmark from '../../../bookmark/bookmark';
import Link from '../../../../components-v2/link';
import AdLabel from '../../../card/pads/ad-label';
import useItem from '../../../../hooks/use-item';
import { normalizePictureData, IsNotCropImage } from '../utils';
import renderCoreContent, {
  renderFreeShippingThMessage,
  renderVariationPicker,
  renderPills,
} from '../desktop-card-content/core';
import renderMotContent from '../desktop-card-content/mot';
import renderResContent from '../desktop-card-content/res';
import renderSrvContent from '../desktop-card-content/srv';
import renderCpgContent from '../desktop-card-content/cpg';
import renderCatContent from '../desktop-card-content/cat';
import SuggestionsContainer from '../../../suggestions/suggestions.desktop';
import { SuggestContext } from '../../../context/suggest-cpg';
import { useSearch } from '../../../../hooks/context';
import {
  CAROUSEL_SETTINGS,
  VERTICAL_TYPE_SRV,
  VERTICAL_TYPE_CORE,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_RES,
  VERTICAL_TYPE_CPG,
  GRID,
  STACK,
  namespace,
} from '../constants';
import { renderMediaTagGroup } from '../desktop-card-content/common';
import ItemOfficialStore from '../../../card/official-store/official-store-label';

function verticalContentRenderer(item, options, layout, itemContext, frontend_layout_type) {
  const isCategoryPage = frontend_layout_type === 'CATEGORY';
  const { vertical } = item;

  switch (vertical) {
    case VERTICAL_TYPE_SRV:
      return renderSrvContent(item, options, layout);
    case VERTICAL_TYPE_MOT:
      return renderMotContent(item, options, layout);
    case VERTICAL_TYPE_RES:
      return renderResContent(item, options, layout);
    case VERTICAL_TYPE_CPG:
      return renderCpgContent(item, options, layout);
    case VERTICAL_TYPE_CORE:

    // falls through
    default:
      return isCategoryPage
        ? renderCatContent(item, options, layout)
        : renderCoreContent(item, options, layout, itemContext);
  }
}

const PictureSlide = ({ picture }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <CarouselSnappedSlide key={picture.src}>
      <Image
        alt={picture.title}
        className={classnames(`${namespace}-image__element`, {
          [`${namespace}-image__not-crop`]: picture.isNotCropImage,
          'shops__image-element': isShops,
        })}
        src={picture.src}
        preload
        lazyload={picture.lazyload}
        lowEnd={picture.lowEnd}
        width={picture.width}
        height={picture.height}
        mounted={picture.mounted}
        carousel
      />
    </CarouselSnappedSlide>
  );
};

const renderContent = (item, options, layout, itemContext, frontend_layout_type) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const isCpg = Boolean(item.cpg);
  const adLabel = item.is_ad && (
    <AdLabel adVersion={item.ad_version} text={item.ad_label} shouldRenderLinks={options.cardSupportsLinks} />
  );

  switch (layout) {
    case GRID:
      return (
        <>
          <Wrapper adLabel={adLabel} className={`${namespace}__content`} isCpg={isCpg} item={item}>
            <div className={classnames(`${namespace}__content-wrapper`, { 'shops__result-content-wrapper': isShops })}>
              {verticalContentRenderer(item, options, layout, itemContext, frontend_layout_type)}
            </div>
            {isCpg && renderFreeShippingThMessage(item, layout)}
            {!isCpg && adLabel}
          </Wrapper>
          {item.vertical === VERTICAL_TYPE_RES &&
            renderMediaTagGroup({ media: item.media, layout, highlighted: item?.highlighted })}
        </>
      );
    case STACK:

    // falls through
    default:
      return (
        <div className={classnames(`${namespace}__content-wrapper`, { 'shops__result-content-wrapper': isShops })}>
          {verticalContentRenderer(item, options, layout, itemContext, frontend_layout_type)}
          {adLabel}
        </div>
      );
  }
};

const renderShopsPills = (item) => <div className={`${namespace}__shops-pills--absolute`}>{renderPills(item)}</div>;

function renderOfficialStoreItem(official_store, options, layout) {
  return (
    <ItemOfficialStore
      label={official_store.verbose_text}
      permalink={official_store.permalink}
      shouldRenderLinks
      title={official_store.verbose_text.text}
      tracks={official_store.tracks}
      className="official-store--pi"
      layout={layout}
    />
  );
}

function renderRealEstateAgencyLogo(item, options, layout) {
  const superPremium = includes(item.tags, 'super_premium');
  const isMultifamily = item.is_multifamily ?? false;

  if (superPremium || isMultifamily) {
    const realEstateAgencyLogo = get(item, 'seller_info.real_estate_agency_logo');
    const realEstateFantasyName = get(item, 'seller_info.real_estate_agency_fantasy_name');
    const isOfficialStoreAndStackLayout = layout === STACK && item.official_store;

    if (realEstateAgencyLogo) {
      return (
        <div className={`${namespace}-item-super_premium`}>
          <Image
            className={`real-estate-agency-logo__image--${layout}`}
            src={realEstateAgencyLogo}
            lazyload="on"
            lowEnd={options.lowEnd === true}
            alt={realEstateFantasyName}
          />
          {isOfficialStoreAndStackLayout ? (
            renderOfficialStoreItem(item.official_store, options)
          ) : (
            <span className="real-estate-agency-name">{realEstateFantasyName}</span>
          )}
        </div>
      );
    }

    return isOfficialStoreAndStackLayout ? (
      renderOfficialStoreItem(item.official_store, options)
    ) : (
      <span className="real-estate-agency-onlyname">{realEstateFantasyName}</span>
    );
  }

  return null;
}

// TODO: render creado para la VI
const DesktopLayoutItem = ({ index, item: middlewareItem, layout, onMount = undefined, options }) => {
  const { bookmark, frontend_layout_type, shops } = useSearch();
  const isShops = Boolean(shops);
  const cardRef = useRef(null);

  const itemContext = useItem({ index, middlewareItem, layout, onMount, cardRef });

  const { item, selectedVariation, itemPictures, isLoadingPictures, handleCardHover } = itemContext;

  const { title, vertical, image_ratio } = item;

  const shouldNotCropImage = IsNotCropImage(vertical, image_ratio);

  const itemVertical = vertical ? vertical.toLowerCase() : null;
  // @TODO Quitar esto cuando se simplifique si es gallery o grid para desktop.
  const isGridLayout = layout === GRID;

  const variationPicker =
    isGridLayout && (options.renderVariationPicker === undefined ? true : options.renderVariationPicker);

  const [carouselSettings] = useState(CAROUSEL_SETTINGS);

  const resultWrapperStyles = classnames(`${namespace}__wrapper`);
  const cardStyles = classnames(namespace, {
    [`${namespace}--${itemVertical}`]: itemVertical,
    shops__cardStyles: isShops,
  });
  const picturesStripStyles = classnames(`${namespace}__image`, {
    [`${namespace}__image--loading`]: isLoadingPictures(item.id),
    shops__picturesStyles: isShops,
  });

  const { currentItemSuggestion } = useContext(SuggestContext);

  const realEstateAgencyLogo = renderRealEstateAgencyLogo(item, options, layout);

  return (
    <div
      className={classnames(resultWrapperStyles, { 'shops__result-wrapper': isShops })}
      ref={cardRef}
      onMouseEnter={handleCardHover}
      onFocus={handleCardHover}
    >
      <Card animated={isGridLayout} className={cardStyles}>
        {isShops && renderShopsPills(item)}
        <div className={picturesStripStyles}>
          <CarouselSnapped
            className={`${namespace}__card`}
            key={get(selectedVariation, 'id', get(item, 'id'))}
            strictBoundaries={false}
            pagination={carouselSettings.dots}
            type="full"
            srLabel={item.title}
            slidesPerView={carouselSettings.slidesToShow}
            arrows={{ visibility: 'hover' }}
            infinite={carouselSettings.infinite}
            lazyload
          >
            {itemPictures
              .map((picture) => ({
                picture: normalizePictureData(picture, title, options, shouldNotCropImage),
                item,
              }))
              .map(PictureSlide)}
          </CarouselSnapped>

          {layout === GRID && realEstateAgencyLogo && (
            <Link
              className={picturesStripStyles}
              href={item.permalink}
              title={title}
              isInternal={false}
              target={item.target}
            >
              {realEstateAgencyLogo}
            </Link>
          )}

          {variationPicker && renderVariationPicker(options, layout, itemContext)}
        </div>
        {renderContent(item, options, layout, itemContext, frontend_layout_type)}
        {bookmark?.show_user_bookmarks && !item.pds_info && (
          <div className={classnames(`${namespace}__bookmark`, { [`${namespace}__bookmark-active`]: item.bookmarked })}>
            <ItemBookmark item={item} />
          </div>
        )}
        <SuggestionsContainer
          permalink={item.permalink}
          title={item.title}
          category={item.category_id}
          itemId={item.id}
          cpg={item.cpg}
          currentItemSuggestion={currentItemSuggestion}
          availableQuantity={item.available_quantity}
        />
      </Card>
    </div>
  );
};

DesktopLayoutItem.propTypes = {
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  options: shape({
    lowEnd: bool,
  }),
  onMount: func,
};

export default DesktopLayoutItem;
