import url from 'url';

import omit from 'lodash/omit';

import { BLACKLISTED_PARAMS, layoutRegex } from '../constants';

const mergeQueryParams = (currentUrl, queryParams) => {
  const newUrl = Object.assign(currentUrl);

  const oldQuery = newUrl.search;
  const urlParams = new URLSearchParams(oldQuery);
  const oldQueryObj = Object.fromEntries(urlParams);
  const newQuery = { ...queryParams, ...oldQueryObj };

  const params = omit(newQuery, BLACKLISTED_PARAMS);

  Object.keys(params).forEach((key) => {
    if (!newUrl.searchParams.has(key)) {
      newUrl.searchParams.append(key, params[key]);
    }
  });

  return newUrl;
};

const updateLayoutPath = (currentUrl, currentLayout, initialLayout) => {
  const newUrl = Object.assign(currentUrl);

  if (currentLayout && currentLayout.id !== initialLayout) {
    const oldLayout = layoutRegex.exec(newUrl.pathname);
    const newLayout = currentLayout.url_path.match(layoutRegex);

    if (oldLayout) {
      newUrl.pathname = newUrl.pathname.replace(oldLayout[0], newLayout);
    } else {
      newUrl.pathname += newLayout;
    }
  }

  return newUrl;
};

const linkBuildNewHref = ({ href, isInternal, queryParams, keepLayout, currentLayout, initialLayout }) => {
  if (!href) {
    return undefined;
  }

  if (href && !isInternal) {
    return href;
  }

  const newUrl = mergeQueryParams(new URL(href, currentLayout?.url_host), queryParams);

  return url.format(keepLayout ? updateLayoutPath(newUrl, currentLayout, initialLayout) : newUrl);
};

export default linkBuildNewHref;
