import React from 'react';
import { shape, string } from 'prop-types';
import cx from 'classnames';
import ItemTitle from '../../../../card/title';
import HightlightLabel from '../../../../card/highlight/highlight-label';
import { Price } from '../common/price';
import { Attributes } from '../common/attributes';
import { namespace } from '../../constants';
import { DEVICE_TYPES } from '../../../../../constants';
import { renderHighlightGroup } from '../../desktop-card-content/common';
import IconOnlineVisitRequest from '../../../../icons/online-visit-request';

const { MOBILE, DESKTOP } = DEVICE_TYPES;
const ITEM_SEPARATOR = '·';
const TIME_LABEL_HIGHLIGHT = 'TIME_LABEL_HIGHLIGHT';

const ResContent = ({
  item: {
    highlight,
    media,
    price,
    discountSource,
    from,
    location,
    descriptions,
    title,
    sub_title,
    available_units,
    online_visit_request,
  },
  device,
  permalink,
  target,
  classNameLink,
  titleA11Y,
}) => (
  <div className={`${namespace}__content`}>
    {highlight?.type !== TIME_LABEL_HIGHLIGHT && renderHighlightGroup({ highlight })}
    {media && <HightlightLabel {...media} />}
    <ItemTitle
      className={cx(`${namespace}__content-title`, `${namespace}__content-separator--4`)}
      title={device === DESKTOP ? sub_title : title}
      permalink={permalink}
      target={target}
      classNameLink={classNameLink}
      titleA11Y={titleA11Y}
    />
    <Price className={`${namespace}__content-separator--4`} price={price} from={from} discountSource={discountSource} />
    {highlight?.type === TIME_LABEL_HIGHLIGHT && renderHighlightGroup({ highlight })}
    {descriptions && (
      <Attributes
        className={cx({
          [`${namespace}__content-separator--2`]: device === MOBILE,
        })}
        attributes={descriptions}
        separator={ITEM_SEPARATOR}
      />
    )}
    <div className={`${namespace}__content-location`}>{location}</div>
    {available_units && <div className={`${namespace}__available-units`}>{available_units}</div>}
    {online_visit_request && (
      <div className={`${namespace}__online-visit-request`}>
        <IconOnlineVisitRequest />
        {online_visit_request}
      </div>
    )}
  </div>
);

ResContent.propTypes = {
  device: string.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  permalink: string.isRequired,
  target: string.isRequired,
  classNameLink: string,
  titleA11Y: string,
};

export { ResContent };
