import React from 'react';

import classNames from 'classnames';

import IconFull from '../icons/full';
import IconFullSuper from '../icons/full-super';
import IconFullSuperSmall from '../icons/full-super-small';
import IconFullSuperMlb from '../icons/full-super-mlb';
import IconFullSuperSmallMlb from '../icons/full-super-small-mlb';
import IconSupermarketLogo from '../icons/supermarket-logo';
import IconMelicoin from '../icons/icon-melicoin';
import IconVirtualTour from '../icons/virtual-tour';
import IconMelichoice from '../icons/melichoice';
import IconInternationalFilter from '../icons/international-filter-cbt';
import MeliPlusIcon from '../icons/meli-plus';
import IconInternationalFilterMobile from '../icons/international-filter-cbt-mobile';

const renderIcon = (type, className) => {
  const normalizedClassName = className ?? '';

  switch (type) {
    case 'fulfillment':
      return <IconFull />;
    case 'full_super':
      return <IconFullSuper />;
    case 'full_super_mlb':
      return <IconFullSuperMlb />;
    case 'full_super_small':
      return <IconFullSuperSmall />;
    case 'full_super_small_mlb':
      return <IconFullSuperSmallMlb />;
    case 'supermarket':
    case 'supermarket_logo':
      return <IconSupermarketLogo className={classNames(normalizedClassName)} />;
    case 'melicoin':
      return <IconMelicoin />;
    case 'virtual_tour_icon':
      return <IconVirtualTour />;
    case 'cbt_international_desktop':
      return <IconInternationalFilter />;
    case 'cbt_international_mobile':
      return <IconInternationalFilterMobile />;
    case 'meli_plus_icon':
      return <MeliPlusIcon />;
    case 'melichoice_icon':
      return <IconMelichoice />;

    default:
      return null;
  }
};

export default renderIcon;
