import React from 'react';
import { string, shape, arrayOf, func } from 'prop-types';

import classnames from 'classnames';
import Tag from '@andes/tag';

import { FilterLink } from '../../../components-v2/link';
import { getVisibleFilters } from './helpers';

const namespace = 'ui-search-applied-filters';

const AppliedFilters = ({ filters = [], className = '', onClose = () => {} }) => (
  <section className={classnames(namespace, className)}>
    {filters?.map((filter) =>
      filter.values.map((it) => (
        <FilterLink href={it.url} key={it.id} className={`${namespace}__link`} title={it?.unapply_filter_text}>
          <Tag label={it.name} onClose={onClose} closeButtonSrLabel={it.unapply_filter_text} />
        </FilterLink>
      )),
    )}
  </section>
);

AppliedFilters.propTypes = {
  className: string,
  filters: arrayOf(
    shape({
      id: string.isRequired,
      values: arrayOf(
        shape({
          id: string.isRequired,
          name: string.isRequired,
          url: string.isRequired,
        }),
      ),
    }),
  ),
  onClose: func,
};

const AppliedFiltersContainer = ({ filters = [], className = '', onClose = () => {} }) => {
  const visibleFilters = getVisibleFilters(filters);

  return (
    visibleFilters &&
    visibleFilters.length > 0 && <AppliedFilters filters={visibleFilters} className={className} onClose={onClose} />
  );
};

AppliedFiltersContainer.propTypes = {
  className: string,
  filters: arrayOf(shape({})),
  onClose: func,
};

export default AppliedFilters;
export { AppliedFiltersContainer };
