/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback, startTransition, useEffect } from 'react';

import debounce from 'lodash/debounce';

import ServiceAddToCart from '../service';
import ServiceAddToCartShops from '../service/shops';
import { requestQueueDefault, ACTION, ITEM_THRESHOLD_TYPE, THRESHOLD_LABEL_ACTION } from '../utils';
import { SnackbarContext } from '../../../context/snackbar';
import { StaticPropsContext } from '../../../context/static-props';
import { FreeShippingThContext } from '../../../context/free-shipping-th';
import { FullFreeshippingContext } from '../../../context/full-freeshipping';
import { InterventionContext } from '../../../context/intervention';
import { useSearch } from '../../../../hooks/context';
import useEshopsEnvironment from '../../../eshop-environment/use-eshops-environment';
import useSuggestions from './useSuggestions';

const { ADD, REMOVE } = ACTION;
const DEFAULT_DELAY = 500;
const PARTNER = 'PARTNER';
const FULL_SUPER = 'FULL_SUPER';
const NN_SUPER_SITES_ID = ['MLB', 'MLM', 'MLC'];
const { DEFAULT_THRESHOLD_LABEL, THRESHOLD_LABEL_FS_PROMISE } = ITEM_THRESHOLD_TYPE;
const {
  CHANGE_THRESHOLD_PARTNER_TEXT,
  CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT,
  CHANGE_THRESHOLD_SUPER_TEXT,
  CHANGE_THRESHOLD_PROMISE_SUPER_TEXT,
} = THRESHOLD_LABEL_ACTION;

const updateCartFreya = () => {
  if (window?.freya) {
    window.freya.emit('cart:refresh');
  }
};

const getSiteFromItem = (itemId) => {
  if (itemId && typeof itemId === 'string') {
    return itemId.slice(0, 3);
  }

  return null;
};

const useCartHandlers = ({
  availableQuantity,
  minimumQuantity,
  itemId,
  category,
  cart_request_initial_delay,
  cart_request_delay,
  should_call_suggestions,
  variation_id,
  isCpg,
  productId,
  inventoryId,
  weight_data,
  tracks,
  groupBy,
  threshold,
  viewMode,
  isAd,
  permalink,
  label_count,
  quantity,
  isPolycard = false,
  isShops,
}) => {
  const {
    getCPGInfo,
    getPreloadedLabelCount,
    getQuantityCart,
    getQuantityCartPrevious,
    getQuantityCartRequested,
    setLabelCount,
    setLabelCountValues,
    setPreloadedLabelCount,
    setQuantityCart,
    setQuantityCartPrevious,
    setQuantityCartRequested,
    sameItemList,
    setSameItemList,
    setPartialPreloadedLabelCount,
    setThresHoldPoly,
    getItemTitle,
    getItemPrice,
    getItemBrand,
    getItemVariation,
    shops,
  } = useSearch();
  const { getSuggestions } = useSuggestions();
  const { queryParams } = useContext(StaticPropsContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { isEshopsEnvironment } = useEshopsEnvironment();
  const { setFullFreeshipping, isVisible, setFullFreeShippingLoading, showShimmer, setFullFreeShippingVisibility } =
    useContext(FullFreeshippingContext);
  const { thresholdState, thresholdDispatch } = useContext(FreeShippingThContext) || {};
  const { setCurrentInterventionData } = useContext(InterventionContext);
  const [itemCount, setItemCount] = useState(quantity);
  const [firstRequest, setFirstRequest] = useState(true);
  const { freeShippingSuper, freeShippingPartner } = thresholdState;
  const [maxStock, setMaxStock] = useState(availableQuantity);
  const [requestQueue, setRequestQueue] = useState({ ...requestQueueDefault });
  const [pageXUrl, setPageXUrl] = useState(null);
  const labelQuantity = itemId in sameItemList ? sameItemList[`${itemId}`].label_count : label_count;
  const itemQuantity = itemId in sameItemList ? sameItemList[`${itemId}`].quantity : itemCount;
  const itemMaxStock = itemId in sameItemList ? sameItemList[`${itemId}`].max_stock : maxStock;
  const addEnabled = itemQuantity < itemMaxStock;

  useEffect(() => {
    setQuantityCartPrevious(itemId, itemQuantity);
  }, []);

  const getLabelsForQuantity = useCallback(
    (newQuantity) => {
      const cpgInfo = getCPGInfo(itemId);

      let newLabels = getPreloadedLabelCount(itemId, newQuantity);

      if (!newLabels) {
        if (cpgInfo?.label_count?.text.includes('{quantity_text}') && !cpgInfo.preloaded_label_counts) {
          newLabels = cpgInfo.label_count;

          const value = cpgInfo.label_count.values.find((val) => val.key === 'quantity_text');

          value.text = newQuantity.toString();
        }
      }

      return newLabels;
    },
    [getCPGInfo, getPreloadedLabelCount],
  );

  const apiError = (snackbar) => {
    /**
     * Each time there is an error with the api,
     * it is necessary to reset the full freeshipping state to hide the shimmer
     */
    setFullFreeShippingVisibility(false);
    setFullFreeShippingLoading(false);
    setFullFreeshipping(null);

    let previousQuantity = parseInt(getQuantityCartPrevious(itemId), 10);

    if (Number.isNaN(previousQuantity)) {
      previousQuantity = 0;
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: previousQuantity,
        },
      });
    } else {
      setItemCount(previousQuantity);
    }

    setQuantityCart(itemId, previousQuantity);
    setQuantityCartRequested(itemId, null);

    setSnackbar({
      color: 'red',
      message: snackbar?.text ? snackbar.text : 'No se pudo actualizar la cantidad.', // TODO FIXME translate traducir esto
      className: 'ui-search-snackbar-add-cart--error',
    });

    setTimeout(() => {
      setSnackbar(null);
    }, 5000);
  };

  const checkAvailableQuantity = (finalQuantity) => {
    const newLabels = getLabelsForQuantity(finalQuantity);

    if (newLabels) {
      setLabelCount(itemId, newLabels);
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: finalQuantity,
          max_stock: finalQuantity,
          label_count: newLabels ?? label_count,
        },
      });
    } else {
      setMaxStock(finalQuantity);
      setItemCount(finalQuantity);
    }

    setQuantityCart(itemId, finalQuantity);
    setQuantityCartPrevious(itemId, finalQuantity);
  };

  const getThresholdLabelContent = (cartInfo) => {
    const thresholdLabels = cartInfo?.threshold_labels || [];
    const hasThresholdLabels = thresholdLabels.length > 0;

    if (hasThresholdLabels) {
      const thresholdLabelDefault = thresholdLabels.find((th) => th.id === DEFAULT_THRESHOLD_LABEL);
      const thresholdLabelPromise = thresholdLabels.find((th) => th.id === THRESHOLD_LABEL_FS_PROMISE);

      return { hasThresholdLabels, thresholdLabelDefault, thresholdLabelPromise };
    }

    const thresholdLabel = cartInfo?.threshold_label;

    return {
      hasThresholdLabels,
      thresholdLabel,
    };
  };

  const changeFreeShippingText = (cartInfo = {}) => {
    const { group_by } = cartInfo;
    const { hasThresholdLabels, thresholdLabel, thresholdLabelDefault, thresholdLabelPromise } =
      getThresholdLabelContent(cartInfo);

    const getLabels = () => ({
      defaultLabel: hasThresholdLabels ? thresholdLabelDefault : thresholdLabel,
      fsPromiseLabel: hasThresholdLabels ? thresholdLabelPromise : thresholdLabel,
    });

    const dispatchThresholdChange = (defaultThresholdType, promiseThresholdType) => {
      const { defaultLabel, fsPromiseLabel } = getLabels();

      thresholdDispatch({
        type: defaultThresholdType,
        payload: {
          ...cartInfo,
          threshold_labels: defaultLabel,
        },
      });

      thresholdDispatch({
        type: promiseThresholdType,
        payload: {
          ...cartInfo,
          threshold_labels: fsPromiseLabel,
        },
      });
    };

    const dispatchPartnerChange = () => {
      dispatchThresholdChange(CHANGE_THRESHOLD_PARTNER_TEXT, CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT);
    };

    const dispatchSuperChange = () => {
      dispatchThresholdChange(CHANGE_THRESHOLD_SUPER_TEXT, CHANGE_THRESHOLD_PROMISE_SUPER_TEXT);
    };

    if (group_by === PARTNER) {
      dispatchPartnerChange();
    } else if (group_by === FULL_SUPER) {
      dispatchSuperChange();
    }
  };

  const processRequest = (callback) => {
    requestQueue.isRequestInProgress = false;

    if (requestQueue.pendingRequest) {
      requestQueue.pendingRequest();
      requestQueue.pendingRequest = null;
      setRequestQueue(requestQueue);
    } else {
      callback();
      setRequestQueue(requestQueue);
    }
  };

  const updateItemsFromCart = useCallback(
    (newQuantity) => {
      if (itemId in sameItemList) {
        setSameItemList({
          ...sameItemList,
          [itemId]: {
            ...sameItemList[`${itemId}`],
            quantity: newQuantity,
          },
        });
      } else {
        setItemCount(newQuantity);
      }
    },
    [setSameItemList, setItemCount],
  );

  const a2cServiceHooks = {
    apiError,
    checkAvailableQuantity,
    changeFreeShippingText,
    setThresHoldPoly: isPolycard && setThresHoldPoly,
    processRequest,
    setFirstRequest,
    setFullFreeShippingLoading,
    setFullFreeshipping,
    setItemCount: updateItemsFromCart,
    setLabelCountValues,
    setMaxStock,
    setPreloadedLabelCount,
    setQuantityCartRequested,
    setPartialPreloadedLabelCount,
    setCurrentInterventionData,
    setQuantityCartPrevious,
    setPageXUrl,
  };

  const callServiceAddToCart = (polycardUrl) => {
    const newQuantity = getQuantityCart(itemId);
    const previousQuantity = parseInt(getQuantityCartPrevious(itemId), 10);

    if (
      !isEshopsEnvironment &&
      !isVisible &&
      showShimmer &&
      NN_SUPER_SITES_ID.includes(getSiteFromItem(itemId).toUpperCase())
    ) {
      setFullFreeShippingLoading(true);
      setFullFreeshipping({ key: Math.floor(Math.random() * 1000) });
    }

    ServiceAddToCart(
      itemId,
      category,
      variation_id,
      availableQuantity,
      newQuantity,
      isCpg,
      productId,
      inventoryId,
      previousQuantity <= newQuantity ? ADD : REMOVE,
      a2cServiceHooks,
      queryParams,
      weight_data,
      requestQueue || requestQueueDefault,
      tracks,
      updateCartFreya,
      firstRequest,
      groupBy,
      threshold,
      viewMode,
      isEshopsEnvironment,
      isAd,
      polycardUrl ?? permalink,
      previousQuantity,
    );
  };

  const callServiceAddToCartShops = () => {
    const newQuantity = getQuantityCart(itemId);
    const previousQuantity = parseInt(getQuantityCartPrevious(itemId), 10) || 0;
    const mshopsTrackData = {
      shop_id: shops?.data?.shop?.id,
      title: getItemTitle(itemId),
      price: getItemPrice(itemId),
      brand: getItemBrand(itemId),
      category,
    };
    const variationId = getItemVariation(itemId);

    ServiceAddToCartShops(
      itemId,
      variationId,
      newQuantity,
      a2cServiceHooks,
      requestQueue || requestQueueDefault,
      previousQuantity,
      mshopsTrackData,
    );
  };

  const getDelay = () => {
    if (firstRequest) {
      return cart_request_initial_delay || cart_request_delay || DEFAULT_DELAY;
    }

    const quantityNew = getQuantityCart(itemId);
    const alreadyRequested = getQuantityCartRequested(itemId);

    return (
      (quantityNew === 1 && (alreadyRequested === 0 || !alreadyRequested)
        ? cart_request_initial_delay
        : cart_request_delay) || DEFAULT_DELAY
    );
  };

  const debouncedCartService = useCallback(
    debounce((polycardUrl) => (isShops ? callServiceAddToCartShops() : callServiceAddToCart(polycardUrl)), getDelay()),
    [itemId, firstRequest, freeShippingSuper, freeShippingPartner, isVisible],
  );

  const changeCart = useCallback(
    (polycardUrl) => {
      const newQuantity = getQuantityCart(itemId);
      const isCallSuggestions = should_call_suggestions === 'true' || should_call_suggestions === true;

      if (isCallSuggestions) {
        getSuggestions(category, itemQuantity, itemId, newQuantity, queryParams);
      }

      debouncedCartService(polycardUrl);
    },
    [
      getQuantityCart,
      debouncedCartService,
      getSuggestions,
      category,
      itemQuantity,
      itemId,
      queryParams,
      should_call_suggestions,
    ],
  );

  const updateQuantity = useCallback(
    (newQuantity, polycardUrl) => {
      const newLabels = getLabelsForQuantity(newQuantity);

      if (newLabels) {
        setLabelCount(itemId, newLabels);
      }

      startTransition(() => {
        updateItemsFromCart(newQuantity);

        setQuantityCart(itemId, newQuantity);

        if (itemId in sameItemList) {
          setSameItemList({
            ...sameItemList,
            [itemId]: {
              ...sameItemList[`${itemId}`],
              quantity: newQuantity,
              label_count: newLabels ?? label_count,
            },
          });
        }

        changeCart(polycardUrl);
      });
    },
    [getLabelsForQuantity, setQuantityCartPrevious, updateItemsFromCart, setQuantityCart, setSameItemList, changeCart],
  );

  const handleAddToCartDelete = useCallback(
    (e, polycardUrl) => {
      e.preventDefault();

      const itemMinusOne = parseInt(itemQuantity, 10) - 1;

      if (minimumQuantity > 0 && itemMinusOne < minimumQuantity) {
        updateQuantity(0, polycardUrl);
      } else {
        updateQuantity(itemMinusOne, polycardUrl);
      }
    },
    [updateQuantity, itemQuantity, minimumQuantity],
  );

  const handleAddToCartPut = useCallback(
    (e, polycardUrl) => {
      e.preventDefault();

      if (addEnabled) {
        if (minimumQuantity > 0 && itemQuantity < minimumQuantity) {
          if (minimumQuantity > maxStock) {
            updateQuantity(maxStock, polycardUrl);
          } else {
            updateQuantity(minimumQuantity, polycardUrl);
          }
        } else {
          const itemPlusOne = parseInt(itemQuantity, 10) + 1;

          updateQuantity(itemPlusOne, polycardUrl);
        }
      }
    },
    [itemQuantity, minimumQuantity, addEnabled, maxStock],
  );

  return {
    handleAddToCartDelete,
    handleAddToCartPut,
    labelQuantity,
    itemQuantity,
    itemMaxStock,
    pageXUrl,
    setPageXUrl,
  };
};

export default useCartHandlers;
