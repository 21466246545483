import React from 'react';

import { useSearch, useFilters } from '../../hooks/context';
import useFilterSearch from '../../hooks/use-filter-search';
import Link from './link';
import useMapConfig from '../../components/map/hooks/use-map-config';
import { trackClickEvent } from '../../components/map/track';
import { LAYOUTS, FILTERS } from '../../constants';
import { linkPropTypes } from './propTypes';

const checkFilterCondition = (filterId) => {
  const { OPERATION, STATE, CITY, NEIGHBORHOOD, CATEGORY, OPERATION_SUBTYPE } = FILTERS;

  return (
    filterId === OPERATION ||
    filterId === STATE ||
    filterId === CITY ||
    filterId === NEIGHBORHOOD ||
    filterId === CATEGORY ||
    filterId === OPERATION_SUBTYPE
  );
};

const FilterLink = ({ href = '', name, children, filterId, ...props }) => {
  const { layout_options } = useSearch();
  const filterSearch = useFilterSearch(href, filterId);
  const { changeSecondaryFilter } = useFilters();
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const handleClick = (event) => {
    filterSearch(event);
    trackClickEvent(null, track.melidata);

    if (checkFilterCondition(filterId)) {
      changeSecondaryFilter(true);
    }
  };

  return (
    <Link
      {...props}
      href={href}
      onClick={layout_options?.current === LAYOUTS.TYPE_MAP ? handleClick : filterSearch}
      ariaLabelledBy={name}
    >
      {children}
    </Link>
  );
};

FilterLink.propTypes = {
  ...linkPropTypes,
};

export default FilterLink;
